import React from "react"
import Layout from "~/components/common/Layout"
import Quiz from "~/components/page/Quiz"
import { getHeaderTitles, getNextQuizLink, getNextTitle, getPreviousQuizLink } from "~/services/pages"
import withProgress from "~/services/withProgress"

const Page = ({ myPage, mySubpage }) => {
  const questionNumber = 3
  const nextLink = getNextQuizLink(myPage, mySubpage, questionNumber)

  return (
    <Layout
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getPreviousQuizLink(myPage, mySubpage, questionNumber)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
      }}
    >
      <Quiz
        quizNumber={"FOUR"}
        questionNumber={questionNumber}
        questionsTotal={5}
        question={"Which of the following is not a part of TLC praise?"}
        answers={["Tell it", "Like it", "Label it", "Celebrate it"]}
        correctAnswerNumber={1}
        answerDetail={
          "L stands for Label It. Its important that we give the behavior we are praising a label so our girls can have more positive words they can use to describe themselves. "
        }
        nextLink={nextLink}
      ></Quiz>
    </Layout>
  )
}

export default withProgress(Page, 4, 4)
